import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"
// import styled from "styled-components"; 

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
// import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
// import { OutboundLink } from "gatsby-plugin-google-gtag"

import Tier3Layout from "../../components/content/Tier3Layout"
// import ArticleSidebar from "../../components/content/ArticleSidebar"
// import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
// import SampleItineraries from "../../components/content/SampleItineraries"
// import PageSectionHeadline from "../../components/content/PageSectionHeadline"

// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import Carousel from 'react-bootstrap/Carousel'
// import Button from 'react-bootstrap/Button'
// import Modal from 'react-bootstrap/Modal'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
// import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
// import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
// import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons'

import '../../styles/tier3.css'
// import headerimage from '../../images/headerimage-spiritualrestoration.jpg'




const Codeofconduct = () => {
    const pageData = {
                        headline:{
                            text:<>Our Code of Conduct</>,
                            id:'CoC'
                        },
                        sectionnav:{section:'about'},
                        seo:{
                            title:'Our Code of Conduct',
                            description:'We work hard to earn-and keep-your trust.',
                            // image:'/images/headerimage-spiritualrestoration.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('Code of Conduct',location); 
    },[location]);

    // const { search } = useLocation()
    const [showConsultationForm,setShowConsultationForm] = useState(false); 

    // function startConsultationRequest(which){ 
    //     GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
    //     setShowConsultationForm(true); 
    // }
    

  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier3Layout headline={pageData.headline} sectionnav={pageData.sectionnav} showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

            <article>
            <h2>We work hard to earn—and keep—your trust. </h2>
            <p>No matter what your financial situation looks like, we know travel can be a big commitment. You want to get the most value for every dollar, and we want to make sure you feel good about the hard-earned money you spend on your trip. </p>
            <h3>Accurate Prices, Clearly Described</h3>
            <ul>
                <li>We will promote and provide accurate pricing on all travel plans. </li>
                <li>We will clearly document any payment schedules, deadlines, minimums, inclusions, exclusions, restrictions, or any other requirements that are built into your cost. Sometimes there are additional costs during your trip, and if we can anticipate them we’ll disclose them up front. </li>
                <li>We will accurately describe the features and opportunities of a destination or supplier’s offering. </li>
            </ul>
            <h3>Our Clients’ Interests Come First</h3>
            <ul>
                <li>We will design the trip that you want. 
                    <ul>
                        <li>Suppliers pay travel agents a commission and sometimes they offer incentives, but we will never prioritize our revenue over your interests. </li>
                        <li>Suppliers build packages that appeal to a wide variety of travelers, but if the offering doesn’t fit what you’re looking for we won’t try to hard sell it just because it’s easier for us. </li>
                    </ul>
                    </li>
                <li>We will not discriminate against any client or group on the basis of race, ethnicity, heritage, financial capability, sex or gender, sexual orientation, or family structure. We reserve the right to decline business from groups who advocate for, or are inherently structured on, discrimination.</li>
                <li>If you’re planning an event, we treat your customers with respect and will represent your brand well when we have to communicate directly with travelers. </li>
            </ul>
            <h3>We Commit to Confidentiality</h3>
            <ul>
                <li>We keep your personal information private. We will share only the minimum amount required with suppliers when booking your travel experiences. </li>
                <li>We leverage technology best practices to encrypt and protect your data, and we’ll delete it all if you ask us to. </li>
                <li>We may send you promotional communications that are tailored to your interests, but we will not share any personal information with any other company for marketing purposes. </li>
                <li>When planning a trip with you, the details are private. 
                    <ul>
                    <li>We won’t share details of your trip with anyone unless you give us permission. So if it’s a surprise, it’ll stay a surprise! </li>
                    <li>If you’re coordinating a group trip or planning an event, we won’t disclose the full price or any benefits you receive to the people going with you. </li>
                    </ul></li>
            </ul>
            <h3>We’re Responsive and Quick to Communicate</h3>
            <ul>
                <li>We’ll communicate with you often and respond quickly to any questions or concerns you have.</li>
                <li>We’ll respect your time and communication preferences, and treat you with respect throughout every interaction. </li>
                <li>We’ll remind you when critical deadlines are coming up. </li>
                <li>We’ll let you know about anything that might impact your trip as soon as we learn about it - from schedule changes to travel advisories. </li>
            </ul>
            <h3>We Adhere to Travel Industry Rules, Regulations, & Best Practices</h3>
            <ul>
                <li>We will not book travel that circumvents the rules, restrictions, or terms and conditions of our suppliers, including hotels, tour operators, and airlines. </li>
                <li>We are compliant with the codes of conduct and ethical standards of our industry and our accreditation or certification partners. </li>
            </ul>
            </article>



        </Tier3Layout>
    </main>
  )
}


export default Codeofconduct;

