/* Adding Navigation? 
   Remember to update GlobalNav.js too! */ 
   
import React, {useState} from 'react';
import styled from "styled-components"; 
import { Link } from 'gatsby'

import { useLocation } from "@reach/router"

import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { faChevronSquareDown } from '@fortawesome/pro-regular-svg-icons'
import { faChevronSquareUp } from '@fortawesome/pro-regular-svg-icons'

const MenuItems = styled.ul` 
    position:relative; 
    display:block; 
    height:${props => props.responsiveheight ? props.responsiveheight : "200px"}; 
    transition:height 500ms; 
    overflow:hidden; 

    @media (max-width:767.98px) {  
        height:0px; 
        &.show{ 
            height:${props => props.responsiveheight ? props.responsiveheight : "200px"}; 
        }
    }
`; 

export default function SectionMenu({ section }) {

  let menu,breadcrumb; 
  const {pathname} = useLocation(); 

  switch(pathname){ 
    case '/vacations/spiritual/': 
    case '/vacations/spiritual': 
        breadcrumb = <><FontAwesomeIcon icon={faChevronRight} size="sm" /> Spiritual Restoration</>; 
        break; 
    case '/vacations/lgbtq/': 
    case '/vacations/lgbtq': 
        breadcrumb = <><FontAwesomeIcon icon={faChevronRight} size="sm" /> LGBTQ+ &amp; Poly</>; 
        break; 
    case '/vacations/adventure/': 
    case '/vacations/adventure': 
        breadcrumb = <><FontAwesomeIcon icon={faChevronRight} size="sm" /> Adventure</>; 
        break; 
    case '/vacations/cruises/': 
    case '/vacations/cruises': 
        breadcrumb = <><FontAwesomeIcon icon={faChevronRight} size="sm" /> Cruises</>; 
        break; 
    case '/vacations/disney/': 
    case '/vacations/disney': 
        breadcrumb = <><FontAwesomeIcon icon={faChevronRight} size="sm" /> Cruises</>; 
        break; 
    case '/about/codeofconduct/': 
    case '/about/codeofconduct': 
        breadcrumb = <><FontAwesomeIcon icon={faChevronRight} size="sm" /> Code of Conduct</>; 
        break; 
    default:
        breadcrumb = <></>
  }

  const [showSectionMenu,setShowSectionMenu] = useState(false); 

  if(section==='vacations'){ 
    menu = <div class="tier3-menu blue">
          <h5>Vacation Ideas <span className="breadcrumb hidden-md hidden-lg hidden-xl hidden-xxl">{breadcrumb}</span><Button variant="light" className="responsive-menu-opener" onClick={()=>setShowSectionMenu(!showSectionMenu)}><FontAwesomeIcon icon={!showSectionMenu ? faChevronSquareDown:faChevronSquareUp} size="lg" /></Button></h5>
          <MenuItems responsiveheight="250px" className={showSectionMenu && 'show'}>
              <li><Link to="/vacations/adventure" activeClassName="current">Adventure</Link></li>
              <li><Link to="/vacations/cruises" activeClassName="current">Cruises</Link></li>
              <li><Link to="/vacations/lgbtq" activeClassName="current">LGBTQ+ <span className="hidden-xl hidden-lg hidden-md hidden-sm hidden-xs">&amp; Poly-Friendly</span></Link></li>
              <li><Link to="/vacations/spiritual" activeClassName="current"><span className="hidden-xs hidden-sm hidden-md hidden-lg">Spiritual Restoration</span><span className="hidden-xl hidden-xxl">Spirituality</span></Link></li>
              <li><Link to="/vacations/disney" activeClassName="current">Disney World</Link></li>
              <li className="seperator"><hr class="dropdown-divider" /></li>
              {/*<li><Link to="/vacations/favorites" activeClassName="current">Our Favorite Places</Link></li>*/}
              <li><Link to="/vacations" activeClassName="current">All Vacation Ideas</Link></li>
          </MenuItems>
      </div>; 
  } else if(section==='about'){ 
    menu = <div class="tier3-menu purple">
          <h5>About Us <span className="breadcrumb hidden-md hidden-lg hidden-xl hidden-xxl">{breadcrumb}</span><Button variant="light" className="responsive-menu-opener" onClick={()=>setShowSectionMenu(!showSectionMenu)}><FontAwesomeIcon icon={!showSectionMenu ? faChevronSquareDown:faChevronSquareUp} size="lg" /></Button></h5>
          <MenuItems responsiveheight="250px" className={showSectionMenu && 'show'}>
              <li><Link to="/about" activeClassName="current">Meet Outward</Link></li>
              <li><Link to="/about/codeofconduct" activeClassName="current">Our Code of Conduct</Link></li>
          </MenuItems>
      </div>; 
  }

  return (
    <>
      {menu}
    </>
  )
}