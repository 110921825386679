
import React from 'react';

import GlobalNav from "../navigation/GlobalNav"
import SectionMenu from "../navigation/SectionMenu"
import Footer from "../navigation/Footer"
import Tier3Headline from "./Tier3Headline"
import ConsultationRequestForm from "../../components/forms/ConsultationRequestForm"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function Tier3Layout({ children,headline,sectionnav,showConsultForm,onCloseConsultForm }) {

  return (
      <>

        <Container className="no-gutter">
            <GlobalNav /> 
        </Container>
        <Container className="no-gutter">
            {headline.image!==undefined && <Tier3Headline id={headline.id} image={headline.image}>{headline.text}</Tier3Headline>}
            {headline.image===undefined && headline.iframe!==undefined && <Tier3Headline id={headline.id} iframe={headline.iframe} height={headline.hero_height}>{headline.text}</Tier3Headline>}
            {headline.image===undefined && headline.iframe===undefined && <Tier3Headline id={headline.id}>{headline.text}</Tier3Headline>}
        </Container>
        <Container className="no-gutter article">
            <Row>
                <Col md={3} lg={2} xl={2} xxl={2}>
                    <SectionMenu section={sectionnav.section} />
                </Col>
                <Col md={9} lg={10} xl={10} xxl={10}>
                  {children}
                </Col>
            </Row>
            <ConsultationRequestForm show={showConsultForm} onClose={onCloseConsultForm} />
        </Container>
        <Footer />
    </>
  )
}








