
import React from 'react';

export default function Tier3Headline({ children,image,iframe,height,id }) {

    let useIframe = iframe !== undefined; 
    let useImage = !useIframe && (image !== undefined); 
    if(height===undefined) height=200; 
    if(id===undefined) id="Tier3GenericHeadline"; 


  return (
      <>
    <div className="headline">
        <h1>{children}</h1>
        <div className="headlinebg">
            <svg class="h1bg" data-name="H1 BG" xmlns="http://www.w3.org/2000/svg" width="1320" height="63" viewBox="0 0 1320 63">
                <path class="bg-knockout" d="M0,63S10,24.8355,126.8129,24.8355H1320V0H0Z"/>
                <path class="bottom" d="M0,60S12,23,126,24H1320V20H126C23,20,0,38,0,38Z"/>
                <path class="middle" d="M1320,18H126C23,18,0,35,0,35V18S25,6,126,8H1320Z"/>
                <path class="top"  d="M1320,5.9821s-1193.9828.0071-1194.3807,0C16.3465,4.0382,0,15.9519,0,15.9519V0H1320Z"/>
                <polygon class="right-angle"  points="1320 24 1304.538 0 1320 0 1320 24"/>
            </svg>
        </div>
    </div>
    { useImage && <img src={image} alt="" id={`${id}_HeaderArt`} className="Tier3HeaderArt" /> }
    { useIframe && <iframe title="HeaderArt" id={`${id}_HeaderArt`} className="Tier3HeaderArt" width="100%" height={height} frameBorder="0" src={iframe}></iframe>
 }
    </>
  )
}








